<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form data-vv-scope="cupomForm" method="post">
      <div class="row justify-content-md-center p-3">

        <div class="col-12 col-md-12">

          <!-- Cliente -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <advanced-search emptyMessage="Nenhum cliente selecionado" name="formData.idCliente"
                  rootClassName="col-sm-10 row" @onSelect="setCustomer" :value="formData.idCliente"
                  :clearable="false" :options="customerList" v-bind:fetchOptions="customerFetchOptions">
                  <template v-slot:info>
                    <div class="row">
                      <div class="col-md-12">
                        <transition name="slide" mode="">
                          <small v-if="!validCustomer()" class="royalc-error-field">{{ 'Campo obrigatório.' }}</small>
                        </transition>
                      </div>
                    </div>
                  </template>
                </advanced-search>
              </div>
            </div>
          </div>

          <!--  Período -->
          <div class="card">
            <div class="card-body">
              <h5 class="box-title mb-3">{{ $t('form.cupom.section.periodo') }}</h5>
              <div class="row">
                <div class="col-12 col-md-6">
                    <label>{{ this.$i18n.t('form.cupom.dataInicioVigencia') }}</label>
                    <md-datepicker name="formData.dataInicioVigencia" v-model="formData.dataInicioVigencia"
                      md-immediately v-validate="{ required: true }"/>
                    <transition name="slide" mode="">
                      <small v-if="errors.first('formData.dataInicioVigencia')"
                        class="royalc-error-field">{{ errors.first('formData.dataInicioVigencia') }}</small>
                    </transition>
                  </div>
                <div class="col-12 col-md-6">
                  <label>{{ this.$i18n.t('form.cupom.dataValidade') }}</label>
                  <md-datepicker name="formData.dataValidade" v-model="formData.dataValidade"
                    md-immediately v-validate="{ required: true }"/>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('formData.dataValidade')"
                      class="royalc-error-field">{{ errors.first('formData.dataValidade') }}</small>
                  </transition>
                </div>
              </div>
            </div>
          </div>

          <!-- Cupom -->
          <div class="card">
            <div class="card-body">
              <h5 class="box-title mb-3">{{ $t('form.cupom.section.cupom') }}</h5>
              <div class="row">
                <div class="col-sm-4 form-group mb-4">
                  <label>{{ this.$i18n.t('form.cupom.tipoCupom') }}</label>
                  <v-select  class="md-form-control"
                    name="formData.idTipoCupom" label="titulo" :clearable="false" :searchable="true"
                    v-model="formData.idTipoCupom" @search="cupomFetchOptions" :options="cupomList"
                    v-validate="{ required: true, required_object: true }">
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.titulo }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.titulo }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('formData.idTipoCupom')"
                      class="royalc-error-field">{{ errors.first('formData.idTipoCupom') }}</small>
                  </transition>
                </div>
                <!-- Moeda -->
                <div class="col-sm-4 form-group mb-4">
                  <label>{{ this.$i18n.t('form.cupom.moeda') }}</label>
                  <v-select  class="md-form-control" name="formData.idmoeda" label="sigla" :clearable="false" :searchable="true"
                             v-model="formData.idmoeda"
                             @search="currencyFetchOptions" :options="currencyList"
                             v-validate="{ required: false, required_object: false }"
                             :filter-by="currencyFilter" >
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }} {{ option.sigla ? '(' + option.sigla + ')' : '' }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }} {{ option.sigla ? '(' + option.sigla + ')' : '' }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('formData.idmoeda')" class="royalc-error-field">{{ errors.first('formData.idmoeda') }}</small>
                  </transition>
                </div>
                <!-- Valor -->
                <custom-input
                  v-model="formData.valor"
                  name="formData.valor"
                  :label="this.$i18n.t('form.cupom.valor')"
                  type="text"
                  rootClassName="col-sm-4 form-group mb-4"
                  inputClassName="md-form-control"
                  v-validate="{ required: true }"
                  :v-mask="{ 'allowPlus':true, 'alias': 'currency', 'prefix': '', 'groupSeparator': '.', 'radixPoint':','}"
                  :error="submitted && errors.first('formData.valor') ? errors.first('formData.valor') : ''">
                </custom-input>
              </div>
            </div>
          </div>

        </div>

        <!-- Submit -->
        <div class="col-12">
          <button type="button" class="btn btn btn-outline-light pull-right" @click="onSave">Salvar</button>
          <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancel">Cancelar</button>
        </div>

      </div>
    </form>
  </div>
</template>

<script>
// Components

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import CustomInput from '@/components/Forms/CustomInput.vue'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import AdvancedSearch from '@/components/Forms/AdvancedSearch.vue'

// Services
import CustomerService from '@/services/CustomerService'
import CurrencyService from '@/services/CurrencyService'
import CupomService from '@/services/CupomService'

export default {
  name: 'ProcessList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.cupom') + ' - %s'
    }
  },
  data () {
    return {
      // Configuration to mount table of selected values
      customerList: [],
      cupomList: [],
      formData: {
        dataInicioVigencia: null,
        dataValidade: null,
        valor: null,
        idTipoCupom: null,
        idCliente: null,
        idmoeda: null
      },
      formErrors: {},
      isLoading: true,
      fullPage: true,
      // List of options to select on select input
      currencyList: [],
      submitted: false
    }
  },
  components: {
    Loading,
    vSelect,
    CustomInput,
    AdvancedSearch
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
    // this.$store.dispatch('SetShowFilter', true)
    // this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    _this.isLoading = true
    // Load basilares data

    let promises = [
      CurrencyService.getCurrencies(),
      CupomService.getTiposCupom()
    ]

    // If is edit populate values
    let id = this.$route.params.id

    if (id) {
      promises.push(CupomService.get(id))
    } else {
      promises.push(CurrencyService.getByInitials('USD'))
      let data = new Date()
      _this.formData.dataInicioVigencia = data
      _this.formData.dataValidade = util.addDays(data, 30)
      _this.formData.valor = 50
    }

    Promise.all(promises).then((values) => {
      // currencyInfo
      let res = values[0]
      _this.hasData = res.data.hasData
      _this.currencyList = res.data.data

      // Add Opção Moeda do Frete
      _this.currencyList.unshift({ id: null, codigo: null, nome: 'Moeda do frete', sigla: null })

      // Cupom Info
      res = values[1]
      _this.cupomList = res.data.data

      // Edit Info
      if (id) {
        let cupom = values[2].data.data
        _this.formData = {
          dataInicioVigencia: new Date(cupom.dataInicioVigencia),
          dataValidade: new Date(cupom.dataValidade),
          valor: cupom.valor,
          idTipoCupom: cupom.idTipoCupom,
          idCliente: cupom.idCliente,
          idmoeda: cupom.idmoeda
        }
        _this.formData.idmoeda = cupom.data.data
      } else {
        if (_this.cupomList.length > 0) {
          _this.formData.idTipoCupom = _this.cupomList[0]
        }

        _this.formData.idmoeda = values[2].data.data
      }
      _this.isLoading = false
    }).catch(() => {
      _this.isLoading = false
    })
  },
  methods: {
    setCustomer (value) {
      this.formData.idCliente = value
    },
    validCustomer () {
      if (this.formData.idCliente) {
        return true
      }

      return false
    },
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'CupomIndex' })
    },
    taxesTransform (value) {
      let objectValue = {
        id: value.id,
        nome: value.nome,
        valor: 0.00,
        idMoeda: 110
      }

      return objectValue
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    currencyFilter (option, label, search) {
      let temp = search.toLowerCase()

      return (option.nome.toLowerCase().indexOf(temp) > -1 || option.sigla.toLowerCase().indexOf(temp) > -1)
    },
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    /**
     * Triggered when the currency search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    currencyFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search
      }

      CurrencyService.getCurrencies(filters).then(res => {
        this.hasData = res.data.hasData
        this.currencyList = res.data.data
        loading(false)
      })
    },
    cupomFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search
      }

      CupomService.getTiposCupom(filters).then(res => {
        this.cupomList = res.data.data
        loading(false)
      })
    },
    customerFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search
      }

      CustomerService.getCustomers(filters).then(res => {
        this.hasData = res.data.hasData
        this.customerList = res.data.data
        loading(false)
      })
    },
    onSave (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validateAll('cupomForm').then(valid => {
        if (valid) {
          _this.isLoading = true

          let data = Object.assign({}, _this.formData)

          data.idmoeda = data.idmoeda.id
          data.idCliente = data.idCliente.id
          data.idTipoCupom = data.idTipoCupom.id

          let id = this.$route.params.id
          if (id) {
            CupomService.put(id, data).then(response => {
              _this.$router.push({ name: 'CupomIndex' })
            }).catch(err => {
              if (err.code === 400) {
                _this.displayErrors(err.errors)
              }
              _this.isLoading = false
            })
          } else {
            CupomService.post(data).then(response => {
              _this.$router.push({ name: 'CupomIndex' })
            }).finally(() => {
              _this.isLoading = false
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .v-select.md-form-control {
    height: 35px;

  }
</style>
